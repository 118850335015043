import React, { useContext } from "react";
import {
	Paper,
	TableRow,
	TableCell
} from "@material-ui/core";
import { FirebaseContext } from "../../../core/firebase";
import { PendingOrganisationRegistrationDTO, SortOrder, PageOperation } from "@vibepay/business-common";
import { useHistory } from "react-router-dom";
import { PageableTable } from "../../../components/table/pageable-table";

export function Registrations({ registrationState }: any) {

	const history = useHistory();
	const firebaseApp = useContext(FirebaseContext);

	function navigateToRegistration(state: any) {
		history.push("/businesses/registration", state);
	}

	const [rowsPerPage] = React.useState(20);

	const fetchData = async (operation: PageOperation, data: any[]): Promise<any[]>  => {
		switch (operation) {
			case PageOperation.NEXT: return loadNextPage(data);
			case PageOperation.PREVIOUS: return loadPreviousPage(data);
			case PageOperation.FIRST: return loadFirstPage();
		}
	};

	const loadFirstPage = async (): Promise<any[]> => {
		if (registrationState === "pending") {
			return firebaseApp.fetchPendingOrganisationRegistrations({ fieldName: "created", order: SortOrder.ASCENDING }, { size: rowsPerPage + 1, operation: PageOperation.FIRST });
		} else if (registrationState === "rejected") {
			return firebaseApp.fetchRejectedOrganisationRegistrations({ fieldName: "created", order: SortOrder.ASCENDING }, { size: rowsPerPage + 1, operation: PageOperation.FIRST });
		} else {
			return [];
		}
	};

	const loadNextPage = async (data: any[]): Promise<any[]> => {
		if (registrationState === "pending") {
			return firebaseApp.fetchPendingOrganisationRegistrations({ fieldName: "created", order: SortOrder.ASCENDING }, { size: rowsPerPage + 1, operation: PageOperation.NEXT, documentId: data[data.length - 2].id });
		} else if (registrationState === "rejected") {
			return firebaseApp.fetchRejectedOrganisationRegistrations({ fieldName: "created", order: SortOrder.ASCENDING }, { size: rowsPerPage + 1, operation: PageOperation.NEXT, documentId: data[data.length - 2].id });
		} else {
			return [];
		}
	};

	const loadPreviousPage = async (data: any[]): Promise<any[]> => {
		if (registrationState === "pending") {
			return firebaseApp.fetchPendingOrganisationRegistrations({ fieldName: "created", order: SortOrder.ASCENDING }, { size: rowsPerPage + 1, operation: PageOperation.PREVIOUS, documentId: data[0].id });
		} else if (registrationState === "rejected") {
			return firebaseApp.fetchRejectedOrganisationRegistrations({ fieldName: "created", order: SortOrder.ASCENDING }, { size: rowsPerPage + 1, operation: PageOperation.PREVIOUS, documentId: data[0].id });
		} else {
			return [];
		}
	};

	const generateRow = (data: any) => {
		let registration = data as PendingOrganisationRegistrationDTO;

		return (
			<TableRow key={registration.id} className="clickable" onClick={() => navigateToRegistration({ registration: registration, registrationState: registrationState })} >
				<TableCell scope="row">
					<div className="table-cell-wrapper data-wrapper uuid">{registration.id}</div>
				</TableCell>
				<TableCell><div className="table-cell-wrapper">{registration.created}</div></TableCell>
				<TableCell><div className="table-cell-wrapper">{registration.accountHolderEmail}</div></TableCell>
				<TableCell><div className="table-cell-wrapper">{registration.plan}</div></TableCell>
				<TableCell><div className="table-cell-wrapper">{registration.organisationDetails.organisationName}</div></TableCell>
			</TableRow>
		);
	};

	return (
		<Paper className="paper">
			<PageableTable
				columnHeaders={["Id", "Created", "Email", "Plan", "Organisation Name"]}
				tableName={registrationState === "pending" ? "Pending Registrations" : "Rejected Registrations"}
				fetchData={fetchData}
				generateRow={generateRow}
				rowsPerPage={rowsPerPage}
			/>
		</Paper>
	);

}