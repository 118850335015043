import React, { useContext } from "react";
import {
	Paper,
	TableRow,
	TableCell
} from "@material-ui/core";
import { FirebaseContext } from "../../../core/firebase";
import { OrganisationDTO, SortOrder, PageOperation } from "@vibepay/business-common";
import { useHistory } from "react-router-dom";
import { PageableTable } from "../../../components/table/pageable-table";

export function Organisations() {

	const history = useHistory();
	const firebaseApp = useContext(FirebaseContext);

	function navigateToOrganisation(state: any) {
		history.push(`/businesses/organisation/${state.organisation.id}/overview`, state);
	}

	const [rowsPerPage] = React.useState(20);

	const fetchData = async (operation: PageOperation, data: any[]): Promise<any[]>  => {
		switch (operation) {
			case PageOperation.NEXT: return loadNextPage(data);
			case PageOperation.PREVIOUS: return loadPreviousPage(data);
			case PageOperation.FIRST: return loadFirstPage();
		}
	};

	const loadFirstPage = async (): Promise<any[]> => {
		return firebaseApp.fetchOrganisations({ fieldName: "created", order: SortOrder.ASCENDING }, { size: rowsPerPage + 1, operation: PageOperation.FIRST });
	};

	const loadNextPage = async (data: OrganisationDTO[]): Promise<any[]> => {
		return firebaseApp.fetchOrganisations({ fieldName: "created", order: SortOrder.ASCENDING }, { size: rowsPerPage + 1, operation: PageOperation.NEXT, documentId: data[data.length - 2].id });
	};

	const loadPreviousPage = async (data: OrganisationDTO[]): Promise<any[]> => {
		return firebaseApp.fetchOrganisations({ fieldName: "created", order: SortOrder.ASCENDING }, { size: rowsPerPage + 1, operation: PageOperation.PREVIOUS, documentId: data[0].id });
	};

	const generateRow = (data: any) => {
		let organisation = data as OrganisationDTO;

		return (
			<TableRow key={organisation.id} className="clickable" onClick={() => navigateToOrganisation({ organisation: organisation })} >
				<TableCell scope="row">
					<div className="table-cell-wrapper data-wrapper uuid">{organisation.id}</div>
				</TableCell>
				<TableCell><div className="table-cell-wrapper">{organisation.created}</div></TableCell>
				<TableCell><div className="table-cell-wrapper">{organisation.plan}</div></TableCell>
				<TableCell><div className="table-cell-wrapper">{organisation.name}</div></TableCell>
			</TableRow>
		);
	};

	return (
		<Paper className="paper">
			<PageableTable
				columnHeaders={["Id", "Created", "Plan", "Organisation Name"]}
				tableName={"Customers"}
				fetchData={fetchData}
				generateRow={generateRow}
				rowsPerPage={rowsPerPage}
			/>
		</Paper>
	);

}