import React from "react";
import {
	Paper,
	AppBar,
	Toolbar,
	Grid,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody
} from "@material-ui/core";
import { Plan } from "@vibepay/business-common";

export function Plans() {
	return (
		<Paper className="paper">
			<AppBar className="search-bar" position="static" color="default" elevation={0}>
				<Toolbar>
					<Grid container spacing={2} alignItems="center">
					</Grid>
				</Toolbar>
			</AppBar>

			<TableContainer>

				<Table>
					<TableHead>
						<TableRow>
							<TableCell>
								Plan Name
							</TableCell>
							<TableCell >
								Monthly Fee
							</TableCell>
							<TableCell >
								Plan
							</TableCell>
							<TableCell >
								Organisation Name
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{
							<>
								{Object.keys(Plan).map(plan =>
									<TableRow key={plan} className="clickable">
										<TableCell scope="row">
											<div className="table-cell-wrapper data-wrapper uuid">{plan}</div>
										</TableCell>
										<TableCell scope="row">
											<div className="table-cell-wrapper data-wrapper uuid">{plan}</div>
										</TableCell>
										<TableCell scope="row">
											<div className="table-cell-wrapper data-wrapper uuid">{plan}</div>
										</TableCell>
										<TableCell scope="row">
											<div className="table-cell-wrapper data-wrapper uuid">{plan}</div>
										</TableCell>
									</TableRow>
								)
								}
							</>
						}
					</TableBody>
				</Table>
			</TableContainer>

		</Paper>
	);

}