import "firebase/firestore";
import "@firebase/functions";
import {
	SortArgs,
	PageArgs,
	PendingOrganisationRegistrationDTO,
	CompanyLookupRequest,
	CompanyLookupResponse,
	CharityLookupRequest,
	CharityLookupResponse,
	KycAssessmentDTO,
	EmailExistsRequest,
	EmailExistsResponse,
	PlatformOrganisationDTO,
	SaleViewDTO,
	StandingOrderViewDTO,
	CustomerViewDTO,
	FirebaseAppBase,
	OrganisationDTO,
	PlatformUpdateOrganisationRequestDTO
} from "@vibepay/business-common";


export default class FirebaseApp extends FirebaseAppBase {

	fetchOrganisations = async (sortArgs: SortArgs, pageArgs: PageArgs): Promise<PlatformOrganisationDTO[]> => {
		let result = await this.httpsCallable("platform_fetchOrganisations",
			{
				sortArgs: sortArgs,
				pageArgs: pageArgs
			},
			{
				headers: {
					"Authorization": `Bearer ${this.token}`
				}
			}
		);
		return result.data;
	};

	fetchOrganisationView = async (organisationId: string): Promise<OrganisationDTO> => {
		let result = await this.httpsCallable("platform_fetchOrganisationView",
			{
				organisationId: organisationId,
			},
			{
				headers: {
					"Authorization": `Bearer ${this.token}`
				}
			}
		);
		return result.data;
	}

	fetchOrganisationSales = async (organisationId: string, sortArgs: SortArgs, pageArgs: PageArgs): Promise<SaleViewDTO[]> => {
		let result = await this.httpsCallable("platform_fetchOrganisationSales",
			{
				organisationId: organisationId,
				sortArgs: sortArgs,
				pageArgs: pageArgs
			},
			{
				headers: {
					"Authorization": `Bearer ${this.token}`
				}
			}
		);
		return result.data;
	};

	fetchOrganisationStandingOrders = async (organisationId: string, sortArgs: SortArgs, pageArgs: PageArgs): Promise<StandingOrderViewDTO[]> => {
		let result = await this.httpsCallable("platform_fetchOrganisationStandingOrders",
			{
				organisationId: organisationId,
				sortArgs: sortArgs,
				pageArgs: pageArgs
			},
			{
				headers: {
					"Authorization": `Bearer ${this.token}`
				}
			}
		);
		return result.data;
	};

	fetchOrganisationCustomers = async (organisationId: string, sortArgs: SortArgs, pageArgs: PageArgs): Promise<CustomerViewDTO[]> => {
		let result = await this.httpsCallable("platform_fetchOrganisationCustomers",
			{
				organisationId: organisationId,
				sortArgs: sortArgs,
				pageArgs: pageArgs
			},
			{
				headers: {
					"Authorization": `Bearer ${this.token}`
				}
			}
		);
		return result.data;
	};

	fetchPendingOrganisationRegistrations = async (sortArgs: SortArgs, pageArgs: PageArgs): Promise<PendingOrganisationRegistrationDTO[]> => {
		let result = await this.httpsCallable("platform_fetchPendingOrganisationRegistrations",
			{
				sortArgs: sortArgs,
				pageArgs: pageArgs
			},
			{
				headers: {
					"Authorization": `Bearer ${this.token}`
				}
			}
		);
		return result.data;
	};

	fetchRejectedOrganisationRegistrations = async (sortArgs: SortArgs, pageArgs: PageArgs): Promise<PendingOrganisationRegistrationDTO[]> => {
		let result = await this.httpsCallable("platform_fetchRejectedOrganisationRegistrations",
			{
				sortArgs: sortArgs,
				pageArgs: pageArgs
			},
			{
				headers: {
					"Authorization": `Bearer ${this.token}`
				}
			}
		);
		return result.data;
	};

	lookupCompany = async (companyLookupRequest: CompanyLookupRequest): Promise<CompanyLookupResponse> => {
		const result = await this.httpsCallable("lookupCompany", companyLookupRequest);
		return result.data;
	};

	lookupCharity = async (charityLookupRequest: CharityLookupRequest): Promise<CharityLookupResponse> => {
		const result = await this.httpsCallable("lookupCharity", charityLookupRequest);
		return result.data;
	};

	assessRegistration = async (kycAssessmentDTO: KycAssessmentDTO): Promise<void> => {
		await this.httpsCallable("assessRegistration", kycAssessmentDTO, {
			headers: {
				"Authorization": `Bearer ${this.token}`
			}
		});
	};

	lookupEmailForUserOrRegistration = async (emailExistsRequest: EmailExistsRequest): Promise<EmailExistsResponse> => {
		const result = await this.httpsCallable("lookupEmailForUserOrRegistration", emailExistsRequest, {
			headers: {
				"Authorization": `Bearer ${this.token}`
			}
		});
		return result.data;
	};

	updateOrganisation = async (updateRequest: PlatformUpdateOrganisationRequestDTO): Promise<void> => {
		await this.httpsCallable("platform_updateOrganisation", updateRequest, {
			headers: {
				"Authorization": `Bearer ${this.token}`
			}
		});
	};
}