import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import {
	BrowserRouter as Router,
	Route
} from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";

import "./assets/scss/main.scss";

ReactDOM.render(
	<Auth0Provider
		domain={process.env.REACT_APP_AUTH0_DOMAIN ? process.env.REACT_APP_AUTH0_DOMAIN : "vibepay-development.eu.auth0.com"}
		clientId={process.env.REACT_APP_AUTH0_PLATFORM_CLIENT_ID ? process.env.REACT_APP_AUTH0_PLATFORM_CLIENT_ID : "m7pHLlcJFpsf4GJ7SvVpLygzLsbDtxmO"} // If not defined defaults to development
		audience={process.env.REACT_APP_AUTH0_PLATFORM_AUDIENCE ? process.env.REACT_APP_AUTH0_PLATFORM_AUDIENCE : "https://business-platform.vibepay.com"}
		redirectUri={window.location.origin}
	>
		<React.StrictMode>
			<Router>
				<Route path="/*" component={App} />
			</Router>
		</React.StrictMode>
	</Auth0Provider>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
