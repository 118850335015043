import React, { useEffect, useState } from "react";
import {
	AppBar,
	Toolbar,
	Grid,
	IconButton,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	CircularProgress,
	Tooltip,
	TableFooter,
	TablePagination
} from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import { TablePaginationActions } from "./table-pagination-actions";
import { PageOperation } from "@vibepay/business-common";

export interface EntityListParams {
	tableName: string,
	columnHeaders: string[],
	rowsPerPage: number,
	generateRow: (data: any) => any,
	fetchData: (operation: PageOperation, data: any[]) => Promise<any[]>;
}

export function PageableTable({ tableName, columnHeaders, rowsPerPage, generateRow, fetchData }: EntityListParams) {

	// State for sales data
	const [loading, setLoading] = useState(true);
	const [, setLoadingError] = useState(false);
	const [data, setData] = useState([] as any[]);
	const [pageNumber, setPageNumber] = useState(0);
	const [lastPage, setLastPage] = useState(false);
	const [rowCount] = React.useState(rowsPerPage);

	const loadFirstPage = async () => {
		setLoadingError(false);
		setLoading(true);

		try {
			const result = await fetchData(PageOperation.FIRST, data); //firebaseApp.fetchOrganisationCustomers(organisation.id, { fieldName: "created", order: "asc" }, { size: rowsPerPage + 1, operation: 'first' });
			if (result.length <= rowCount) {
				setLastPage(true);
			}

			if (result.length <= rowCount) {
				setLastPage(true);
			} else {
				setLastPage(false);
			}

			setData(result);

			setPageNumber(0);
			setLoading(false);
		} catch (err) {
			setLoadingError(true);
			setLoading(false);
		}
	};

	const loadNextPage = async () => {
		if (!lastPage) {
			setLoadingError(false);
			setLoading(true);

			try {
				const result = await fetchData(PageOperation.NEXT, data); //await firebaseApp.fetchOrganisationCustomers(organisation.id, { fieldName: "created", order: "asc" }, { size: rowsPerPage + 1, operation: 'next', documentId: customers[customers.length - 2].id });

				if (result.length > 0) {
					setData(result);
					if (result.length <= rowCount) {
						setLastPage(true);
					} else {
						setLastPage(false);
					}
					setPageNumber(pageNumber + 1);
				}
				setLoading(false);
			} catch (err) {
				setLoadingError(true);
				setLoading(false);
			}
		}
	};

	const loadPreviousPage = async () => {
		setLoadingError(false);
		setLoading(true);

		try {
			const result = await fetchData(PageOperation.PREVIOUS, data); //await firebaseApp.fetchOrganisationCustomers(organisation.id, { fieldName: "created", order: "asc" }, { size: rowsPerPage + 1, operation: 'previous', documentId: customers[0].id });

			if (result.length > 0) {
				if (result.length <= rowCount) {
					setLastPage(true);
				} else {
					setLastPage(false);
				}
				setPageNumber(pageNumber - 1);
				setData(result);

			}
			setLoading(false);
		} catch (err) {
			setLoadingError(true);
			setLoading(false);
		}
	};

	useEffect(() => {
		loadFirstPage();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tableName]);


	const handleChangePage = async (event: any, newPage: number) => {
		if (newPage === 0) {
			loadFirstPage();
		} else if (newPage === pageNumber + 1) {
			await loadNextPage();
		} else if (newPage === pageNumber - 1) {
			await loadPreviousPage();
		}
	};

	const getCount = () => {
		return data.length === (rowCount + 1) ? -1 : (rowCount * pageNumber) + data.length;
	};

	const handleChangeRowsPerPage = () => {
		//setRowsPerPage(parseInt(event.target.value, 10));
		//setPage(0);
	};

	return (<>
		<AppBar className="search-bar" position="static" color="default" elevation={0}>
			<Toolbar>
				<Grid container spacing={2} alignItems="center">

					<Grid item xs>

					</Grid>
					<Grid item>
						<Tooltip title="Reload">
							<IconButton>
								<RefreshIcon className="block" color="inherit" onClick={() => loadFirstPage()} />
							</IconButton>
						</Tooltip>
					</Grid>
				</Grid>
			</Toolbar>
		</AppBar>

		<TableContainer>

			<Table>
				<TableHead>
					<TableRow>
						{columnHeaders.map(header =>
							<TableCell>
								{header}
							</TableCell>
						)}
					</TableRow>
				</TableHead>
				<TableBody>
					{
						loading ? (
							<TableRow>
								<TableCell scope="row" colSpan={columnHeaders.length} style={{ textAlign: "center" }}>
									<CircularProgress />
								</TableCell>
							</TableRow>
						) :
							data && data.length > 0 ? (
								<>
									{data.slice(0, rowCount).map(datum => generateRow(datum))}
								</>
							) : <TableRow>
								<TableCell colSpan={5} style={{ textAlign: "center" }}>
										No {tableName}
								</TableCell>
							</TableRow>}
				</TableBody>
				<TableFooter>
					<TableRow>
						<TablePagination
							colSpan={columnHeaders.length}
							count={getCount()}
							rowsPerPage={rowCount}
							page={pageNumber}
							rowsPerPageOptions={[]}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							ActionsComponent={TablePaginationActions}
						/>
					</TableRow>
				</TableFooter>
			</Table>
		</TableContainer>
	</>

	);

}