import React, { useContext } from "react";
import {
	Paper,
	AppBar,
	Toolbar,
	Grid,
	IconButton,
	TableRow,
	TableCell,
	Typography
} from "@material-ui/core";
import { FirebaseContext } from "../../../core/firebase";
import {
	PlatformOrganisationDTO,
	CustomerViewDTO,
	SortOrder,
	PageOperation
} from "@vibepay/business-common";
import { useHistory } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { PageableTable } from "../../../components/table/pageable-table";


export function OrganisationCustomers(props: any) {

	const history = useHistory();
	const firebaseApp = useContext(FirebaseContext);
	const organisation = props.location?.state?.organisation as PlatformOrganisationDTO;

	const [rowsPerPage] = React.useState(20);

	const fetchData = async (operation: PageOperation, data: any[]): Promise<any[]>  => {
		switch (operation) {
			case PageOperation.NEXT: return loadNextPage(data);
			case PageOperation.PREVIOUS: return loadPreviousPage(data);
			case PageOperation.FIRST: return loadFirstPage();
		}
	};

	const loadFirstPage = async (): Promise<any[]> => {
		return firebaseApp.fetchOrganisationCustomers(organisation.id, { fieldName: "created", order: SortOrder.ASCENDING }, { size: rowsPerPage + 1, operation: PageOperation.FIRST });
	};

	const loadNextPage = async (data: CustomerViewDTO[]): Promise<any[]> => {
		return firebaseApp.fetchOrganisationCustomers(organisation.id, { fieldName: "created", order: SortOrder.ASCENDING }, { size: rowsPerPage + 1, operation: PageOperation.NEXT, documentId: data[data.length - 2].id });
	};

	const loadPreviousPage = async (data: CustomerViewDTO[]): Promise<any[]> => {
		return firebaseApp.fetchOrganisationCustomers(organisation.id, { fieldName: "created", order: SortOrder.ASCENDING }, { size: rowsPerPage + 1, operation: PageOperation.PREVIOUS, documentId: data[0].id });
	};

	const generateRow = (data: any) => {
		let customer = data as CustomerViewDTO;

		return (
			<TableRow key={customer.id} className="clickable">
				<TableCell scope="row">
					<div className="table-cell-wrapper data-wrapper uuid">{customer.id}</div>
				</TableCell>
				<TableCell><div className="table-cell-wrapper">{customer.created}</div></TableCell>
				<TableCell><div className="table-cell-wrapper">{customer.firstName} {customer.lastName}</div></TableCell>
				<TableCell><div className="table-cell-wrapper">{customer.saleCount}</div></TableCell>
				<TableCell><div className="table-cell-wrapper">{customer.standingOrderCount}</div></TableCell>
			</TableRow>
		);
	};

	return (
		<>
			<AppBar className="action-bar" position="static" color="default" elevation={0} style={{ marginTop: "-48px", marginBottom: "48px", marginLeft: "-32px", marginRight: "-32px", width: "auto" }}>
				<Toolbar>
					<Grid container spacing={2} alignItems="center">
						<Grid item>
							<IconButton>
								<ArrowBackIcon className="block" color="inherit" onClick={() => history.goBack()} />
							</IconButton>
						</Grid>
						<Grid item>
							<Typography>{organisation.name}</Typography>
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
			<Paper className="paper">
				<PageableTable
					columnHeaders={["Id", "Created", "Name", "Sales", "Standing Orders"]}
					tableName={"Customers"}
					fetchData={fetchData}
					generateRow={generateRow}
					rowsPerPage={rowsPerPage}
				/>
			</Paper>
		</>
	);

}