import React, { useState, useEffect, useCallback } from "react";
import {
	Router,
	Route,
	Switch,
	useHistory,
	Redirect,
	RouteComponentProps
} from "react-router-dom";
import {
	createMuiTheme,
	createStyles,
	ThemeProvider,
	withStyles,
	WithStyles
} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Navigator, { Category } from "./components/navigator";
import Header from "./components/header";

import PeopleIcon from "@material-ui/icons/People";
import DnsRoundedIcon from "@material-ui/icons/DnsRounded";
import SettingsIcon from "@material-ui/icons/Settings";
import { Registrations } from "./pages/businesses/registrations/registrations";
import FirebaseApp, { FirebaseContext } from "./core/firebase";
import { useAuth0 } from "@auth0/auth0-react";
import { Registration } from "./pages/businesses/registrations/registration";
import { Organisations } from "./pages/businesses/organisations/organisations";
import { OrganisationOverview } from "./pages/businesses/organisations/organisation-overview";
import { OrganisationSales } from "./pages/businesses/organisations/organisation-sales";
import { OrganisationStandingOrders } from "./pages/businesses/organisations/organisation-standing-orders";
import { OrganisationCustomers } from "./pages/businesses/organisations/organisation-customers";
import { Plans } from "./pages/platform/plans";

function Copyright() {
	return (
		<Typography variant="body2" color="textSecondary" align="center">
			{"Copyright © "}
			<Link color="inherit" href="https://material-ui.com/">
				Your Website
			</Link>{" "}
			{new Date().getFullYear()}
			{"."}
		</Typography>
	);
}

let theme = createMuiTheme({
	palette: {
		primary: {
			light: "#63ccff",
			main: "#009be5",
			dark: "#006db3",
		},
	},
	typography: {
		h5: {
			fontWeight: 500,
			fontSize: 26,
			letterSpacing: 0.5,
		},
		h6: {
			fontWeight: 500,
			fontSize: 14,
			letterSpacing: 0.5,
		},
	},
	shape: {
		borderRadius: 8,
	},
	props: {
		MuiTab: {
			disableRipple: true,
		},
	},
	mixins: {
		toolbar: {
			minHeight: 48,
		},
	},
});

theme = {
	...theme,
	overrides: {
		MuiDrawer: {
			paper: {
				backgroundColor: "#18202c",
			},
		},
		MuiButton: {
			label: {
				textTransform: "none",
			},
			contained: {
				boxShadow: "none",
				"&:active": {
					boxShadow: "none",
				},
			},
		},
		MuiTabs: {
			root: {
				marginLeft: theme.spacing(1),
			},
			indicator: {
				height: 3,
				borderTopLeftRadius: 3,
				borderTopRightRadius: 3,
				backgroundColor: theme.palette.common.white,
			},
		},
		MuiTab: {
			root: {
				textTransform: "none",
				margin: "0 16px",
				minWidth: 0,
				padding: 0,
				[theme.breakpoints.up("md")]: {
					padding: 0,
					minWidth: 0,
				},
			},
		},
		MuiIconButton: {
			root: {
				padding: theme.spacing(1),
			},
		},
		MuiTooltip: {
			tooltip: {
				borderRadius: 4,
			},
		},
		MuiDivider: {
			root: {
				backgroundColor: "#404854",
			},
		},
		MuiListItemText: {
			primary: {
				fontWeight: theme.typography.fontWeightMedium,
			},
		},
		MuiListItemIcon: {
			root: {
				color: "inherit",
				marginRight: 0,
				"& svg": {
					fontSize: 20,
				},
			},
		},
		MuiAvatar: {
			root: {
				width: 32,
				height: 32,
			},
		},
	},
};

const drawerWidth = 256;

const styles = createStyles({
	root: {
		display: "flex",
		minHeight: "100vh",
	},
	drawer: {
		[theme.breakpoints.up("sm")]: {
			width: drawerWidth,
			flexShrink: 0,
		},
	},
	app: {
		flex: 1,
		display: "flex",
		flexDirection: "column",
	},
	main: {
		flex: 1,
		padding: theme.spacing(6, 4),
		background: "#eaeff1",
	},
	footer: {
		padding: theme.spacing(2),
		background: "#eaeff1",
	},
});

const categories: Category[] = [
	{
		id: "businesses",
		children: [
			{ id: "registrations", root: true, icon: <PeopleIcon />, showTabs: true, children: [{ id: "pending" }, { id: "rejected" }] },
			{ id: "organisations", root: true, icon: <DnsRoundedIcon /> },
			{ id: "organisation", root: false, icon: <DnsRoundedIcon />, entity: true, showTabs: true, children: [{ id: "overview" }, { id: "sales" }, { id: "standing_orders" }, { id: "customers" }] },
			{ id: "registration", root: false, icon: <DnsRoundedIcon />, entity: true, showTabs: false, children: [] },
		],
	},
	{
		id: "platform",
		children: [
			{ id: "plans", root: true, icon: <SettingsIcon /> },
		],
	},
];

export interface PaperbaseProps extends WithStyles<typeof styles>, RouteComponentProps<any> { }

function Paperbase(props: PaperbaseProps) {
	const { classes } = props;
	const [mobileOpen, setMobileOpen] = React.useState(false);
	const [activeCategory, setActiveCategory] = useState("businesses/registrations");
	//const [activeSubCategory, setActiveSubCategory] = useState("New");
	const [firebaseApp] = useState(new FirebaseApp());
	const { loginWithRedirect, isLoading, isAuthenticated, getAccessTokenSilently, user, logout } = useAuth0();
	const [initialised, setInitialised] = useState(false);

	const history = useHistory();

	const buildCategoryString = useCallback((segments: string[], parentSearchCategories?: Category[], parentActiveCategory?: string[]): string[] => {
		let activeCategory = parentActiveCategory ? parentActiveCategory : [];
		let searchCategories: Category[] = parentSearchCategories ? parentSearchCategories : categories;

		let category = searchCategories.find(category => category.id === segments[0]);

		if (category) {
			activeCategory.push(segments.shift()!);

			if (category.entity) {
				activeCategory.push(segments.shift()!);
			}

			if (category?.children) {
				activeCategory = buildCategoryString(segments, category.children, activeCategory);
			}
		}

		return activeCategory;
	}, []);

	const findCategory = useCallback((segments: string[], parentSearchCategories?: Category[]): Category => {
		let searchCategories: Category[] = parentSearchCategories ? parentSearchCategories : categories;

		let category = searchCategories.find(category => category.id === segments[0]);

		if (category) {
			segments.shift();

			if (category.entity) {
				segments.shift();
			}

			if (category?.children && segments.length > 0) {
				category = findCategory(segments, category.children);
			}
		} else {
			throw new Error();
		}

		return category;
	}, []);

	useEffect(() => {
		if (!isLoading && !isAuthenticated) {
			loginWithRedirect();
		}

		if (!isLoading && isAuthenticated && !initialised && user && user[`${process.env.REACT_APP_AUTH0_AUDIENCE}/roles`].length > 0) {
			const getToken = async () => {
				let token = await getAccessTokenSilently();
				firebaseApp.initialise(token, () => {
					return getAccessTokenSilently();
				});
				setInitialised(true);
			};

			getToken();
		}

		if(isAuthenticated && user && user[`${process.env.REACT_APP_AUTH0_AUDIENCE}/roles`].length === 0){
			logout({
				returnTo: window.location.origin
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user, isLoading, isAuthenticated, loginWithRedirect, getAccessTokenSilently, logout]);

	const parseLocation = useCallback(() => {
		let path = window.location.pathname;
		path = path.replace(/^\/+|\/+$/g, "");

		const segments: string[] = path.split("/");
		if (path.length !== 0) {
			setActiveCategory(buildCategoryString(segments).join("/"));
		}
	}, [buildCategoryString]);

	useEffect(() => {
		parseLocation();
	}, [parseLocation]);

	useEffect(() => {
		history.listen(() => {
			parseLocation();
		});
	}, [history, parseLocation]);

	const onCategorySelected = useCallback((category: string) => {
		setActiveCategory(category);

		const selectedCategoryChildren = findCategory(category.split("/")).children;

		let subCategory: Category | undefined;
		if (selectedCategoryChildren) {
			subCategory = selectedCategoryChildren[0];
		}

		//setActiveSubCategory(subCategory);

		history.push(`/${category.toLowerCase()}/${subCategory ? subCategory.id.toLowerCase() : ""}`);
	}, [history, findCategory]);

	const onSubCategorySelected = useCallback((subCategory: Category) => {
		//setActiveSubCategory(subCategory);
		let targetCategory = activeCategory.split("/");
		targetCategory.pop();
		history.push(`/${targetCategory.join("/").toLowerCase()}/${subCategory.id.toLowerCase()}`, props.location.state);
	}, [history, activeCategory, props]);

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	function getParentCategory(): Category {
		let targetCategory = activeCategory.split("/");
		targetCategory.pop();
		return findCategory(targetCategory);
	}

	const [initialTab] = useState(getParentCategory().showTabs ? getParentCategory().children?.find(child => child.id === activeCategory.split("/")[activeCategory.split("/").length - 1]) : undefined);

	return (
		<>
			{isAuthenticated && initialised ? (
				<FirebaseContext.Provider value={firebaseApp}>
					<ThemeProvider theme={theme}>
						<div className={classes.root}>
							<CssBaseline />
							<nav className={classes.drawer}>

								<Hidden smUp implementation="js">
									<Navigator
										categories={categories}
										onCategorySelected={onCategorySelected}
										activeItemId={activeCategory}
										PaperProps={{ style: { width: drawerWidth } }}
										variant="temporary"
										open={mobileOpen}
										onClose={handleDrawerToggle}
									/>
								</Hidden>
								<Hidden xsDown implementation="css">
									<Navigator categories={categories} onCategorySelected={onCategorySelected} activeItemId={activeCategory} PaperProps={{ style: { width: drawerWidth } }} />
								</Hidden>

							</nav>
							<div className={classes.app}>
								<Header onDrawerToggle={handleDrawerToggle}
									title={activeCategory.split("/")[1]}
									subtitle={activeCategory.split("/")[2]}
									tabs={getParentCategory().showTabs ? getParentCategory().children : []}
									initialTab={initialTab}
									onSubCategorySelected={onSubCategorySelected} />
								<main className={classes.main}>
									<Router history={history}>
										<Switch>
											<Route path="/businesses/organisations" render={(props) => <Organisations />} />
											<Route path="/businesses/organisation/:id/overview" component={OrganisationOverview} />
											<Route path="/businesses/organisation/:id/sales" component={OrganisationSales} />
											<Route path="/businesses/organisation/:id/standing_orders" component={OrganisationStandingOrders} />
											<Route path="/businesses/organisation/:id/customers" component={OrganisationCustomers} />
											<Route path="/businesses/registrations/pending" render={(props) => <Registrations {...props} registrationState="pending" />} />
											<Route path="/businesses/registrations/rejected" render={(props) => <Registrations {...props} registrationState="rejected" />} />
											<Route path="/businesses/registration" component={Registration} />

											<Route path="/platform/plans" component={Plans} />

											<Redirect path="/" to="/businesses/registrations/pending" />
										</Switch>
									</Router>
								</main>
								<footer className={classes.footer}>
									<Copyright />
								</footer>
							</div>
						</div>
					</ThemeProvider>
				</FirebaseContext.Provider>
			) : ("loading")}
		</>
	);
}

export default withStyles(styles)(Paperbase);