import React, { useContext, useEffect, useState, ChangeEvent } from "react";
import {
	Paper,
	AppBar,
	Toolbar,
	Grid,
	TextField,
	Button,
	IconButton,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Tabs,
	Tab,
	makeStyles,
	Theme,
	Typography,
	Box,
	Dialog,
	DialogTitle,
	Collapse,
	Divider,
	RadioGroup,
	FormControlLabel,
	Radio,
	FormLabel,
	CircularProgress,
	Chip,
	Snackbar
} from "@material-ui/core";
import { FirebaseContext } from "../../../core/firebase";
import {
	PendingOrganisationRegistrationDTO,
	KycAssessmentDTO,
	EmailExistsResponse,
	PersonsOfSignificantControl,
	CompanyOfficer,
	PSC_TYPES,
	PreviousCompanyName,
	ApplicationServicesError,
	CompanyLookupResponse
} from "@vibepay/business-common";
import { useHistory, RouteComponentProps } from "react-router-dom";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Alert from "@material-ui/lab/Alert";

interface TabPanelProps {
	children?: React.ReactNode;
	index: any;
	value: any;
}

type KycAssessmentModel = {
	notes?: string,
	pendingRegistrationId?: string,
	riskScore?: number,
	status?: "pending" | "approved" | "rejected",
	summary?: string;
};

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			className="tab-panel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

function a11yProps(index: any) {
	return {
		id: `vertical-tab-${index}`,
		"aria-controls": `vertical-tabpanel-${index}`,
	};
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper,
		display: "flex",
	},
	tabs: {
		minWidth: 216,
		borderRight: `1px solid ${theme.palette.divider}`,
	},
	wrapper: {
		alignItems: "flex-start"
	},
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		borderRadius: 10,
		padding: theme.spacing(2, 4, 3),
	}
}));

interface RegistrationRouteComponentProps {
	registration: PendingOrganisationRegistrationDTO,
	registrationState: String;
}

export function Registration(props: RouteComponentProps<{}, any, RegistrationRouteComponentProps>) {

	const history = useHistory();
	const firebaseApp = useContext(FirebaseContext);
	const pendingRegistration = props.location.state.registration;
	const registrationState = props.location.state.registrationState;
	const [kycAssessment, setKycAssessment] = useState({ status: "pending" } as KycAssessmentModel);
	const [companiesHouseProfile, setCompaniesHouseProfile] = useState(undefined as CompanyLookupResponse | undefined);
	const [charitiesCommissionProfile, setCharitiesCommissionProfile] = useState(undefined as any);

	const classes = useStyles();
	const [value, setValue] = React.useState(0);
	const [modalImg, setModalImg] = useState("");
	const [imageModalTitle, setImageModalTitle] = useState("");
	const [imageModalOpen, setImageModalOpen] = React.useState(false);
	const [officerOpen, setOfficerOpen] = React.useState("");
	const [pscOpen, setPSCOpen] = React.useState("");
	const [saving, setSaving] = useState(false);
	const [emailExists, setEmailExists] = useState(undefined as undefined | EmailExistsResponse);
	const [error, setError] = useState({ didError: false, message: "" });

	const fetchCompanyProfile = async (companyNumber: string) => {
		const result = await firebaseApp.lookupCompany({ companyNumber: companyNumber });
		setCompaniesHouseProfile(result);
	};

	const fetchCharityProfile = async () => {
		const result = await firebaseApp.lookupCharity({ charityNumber: pendingRegistration.organisationDetails.charityNumber! });
		setCharitiesCommissionProfile(result.charityDetails);
		if (result.charityDetails.RegisteredCompanyNumber) {
			fetchCompanyProfile((result.charityDetails.RegisteredCompanyNumber as string).padStart(8, "0"));
		}
	};

	useEffect(() => {
		firebaseApp.lookupEmailForUserOrRegistration({ email: pendingRegistration.accountHolderEmail }).then(result => {
			setEmailExists(result);
		});

		if (pendingRegistration.organisationDetails.organisationType === "registered") {
			fetchCompanyProfile(pendingRegistration.organisationDetails.companyNumber!);
		} else if (pendingRegistration.organisationDetails.organisationType === "charity") {
			fetchCharityProfile();
		}

		if (pendingRegistration.kycAssessment) {
			setKycAssessment(pendingRegistration.kycAssessment as KycAssessmentModel);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const openImageModal = (src: string, title: string) => {
		setModalImg(src);
		setImageModalTitle(title);
		setImageModalOpen(true);
	};

	const closeImageModal = () => {
		setImageModalOpen(false);
	};

	const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
		setValue(newValue);
	};

	const handleAssessmentChange = (event: ChangeEvent<HTMLInputElement>) => {
		switch (event.currentTarget.name) {
			case "notes":
				kycAssessment.notes = event.currentTarget.value;
				break;
			case "summary":
				kycAssessment.summary = event.currentTarget.value;
				break;
			case "riskScore":
				kycAssessment.riskScore = Number.parseFloat(event.currentTarget.value);
				break;
			case "status":
				kycAssessment.status = event.currentTarget.value as "pending" | "approved" | "rejected" | undefined;
		}

		setKycAssessment(Object.assign({}, kycAssessment));
	};

	const submitKycAssessment = async () => {
		setSaving(true);
		if (kycAssessment && validateKyc()) {
			kycAssessment.pendingRegistrationId = pendingRegistration.id;

			try {
				await firebaseApp.assessRegistration(kycAssessment as KycAssessmentDTO);
			}
			catch (err) {
				if (err instanceof ApplicationServicesError) {
					setError({ didError: true, message: `Unable to process assessment${err.getCode() === "030303" ? " due to a platform dependency error." : "."}` });
				} else {
					console.log(err);
					setError({ didError: true, message: "An unknown error occured" });
				}
			}
		}
		setSaving(false);
	};

	const validateKyc = (): boolean => {
		if (kycAssessment.status === "pending" ||
			// Approved or Rejected
			(
				(kycAssessment.status === "approved" || kycAssessment.status === "rejected") && (
					(kycAssessment.riskScore && kycAssessment.riskScore >= 0) && (kycAssessment.riskScore && kycAssessment.riskScore <= 3)
				)
			)
		) {
			return true;
		}

		return false;
	};

	const renderCompaniesHouseProfile = (companiesHouseProfile: CompanyLookupResponse) => {
		return <TabPanel value={value} index={3}>
			<Grid container spacing={2}>
				<Grid container item xs={12} spacing={1}>
					<Grid item xs={12}>
						<Typography variant="subtitle1">Details</Typography>
					</Grid>
					<Grid item xs={4}>
						<label>Company Name</label>
						<Typography variant="body1">{companiesHouseProfile.companyProfile.company_name}</Typography>
					</Grid>
					<Grid item xs={4}>
						<label>Company Number</label>
						<Typography variant="body1">{companiesHouseProfile.companyProfile.company_number}</Typography>
					</Grid>
					{companiesHouseProfile.companyProfile.company_status ?
						<Grid item xs={4}>
							<label>Company Status</label>
							<Typography variant="body1">{companiesHouseProfile.companyProfile.company_status}</Typography>
						</Grid> :
						null
					}
					<Grid item xs={4}>
						<label>Registered Office</label>
						<Typography variant="body1">
							{companiesHouseProfile.companyProfile.registered_office_address ?
								<>
									{companiesHouseProfile.companyProfile.registered_office_address.address_line_1 ? <>{companiesHouseProfile.companyProfile.registered_office_address.address_line_1}<br /></> : null}
									{companiesHouseProfile.companyProfile.registered_office_address.address_line_2 ? <>{companiesHouseProfile.companyProfile.registered_office_address.address_line_2}<br /></> : null}
									{companiesHouseProfile.companyProfile.registered_office_address.locality ? <>{companiesHouseProfile.companyProfile.registered_office_address.locality}<br /></> : null}
									{companiesHouseProfile.companyProfile.registered_office_address.region ? <>{companiesHouseProfile.companyProfile.registered_office_address.region}<br /></> : null}
									{companiesHouseProfile.companyProfile.registered_office_address.postal_code ? <>{companiesHouseProfile.companyProfile.registered_office_address.postal_code}<br /></> : null}
									{companiesHouseProfile.companyProfile.registered_office_address.country ? <>{companiesHouseProfile.companyProfile.registered_office_address.country}<br /></> : null}
								</> : null
							}
						</Typography>
					</Grid>
					<Grid item xs={4}>
						<label>Jurisdiction</label>
						<Typography variant="body1">{companiesHouseProfile.companyProfile.jurisdiction}</Typography>
					</Grid>
					{companiesHouseProfile.companyProfile.previous_company_names && companiesHouseProfile.companyProfile.previous_company_names.length > 0 ? (
						<Grid item xs={4}>
							<label>Previous Names</label>
							<Typography variant="body1">{
								companiesHouseProfile.companyProfile.previous_company_names.map(
									(pcn: PreviousCompanyName, i: number) => `${pcn.name}${i === companiesHouseProfile.companyProfile.previous_company_names!.length ? "" : ", "}`
								)}
							</Typography>
						</Grid>
					) : ""}
				</Grid>
				<Grid item xs={12}>
					<Divider light></Divider>
				</Grid>
				{companiesHouseProfile.companyProfile.foreign_company_details ? (
					<>
						<Grid container item xs={12} spacing={1}>
							<Grid item xs={12}>
								<Typography variant="subtitle1">Foreign Entity Details</Typography>
							</Grid>
							<Grid item xs={4}>
								<label>Governed By</label>
								<Typography variant="body1">{companiesHouseProfile.companyProfile.foreign_company_details.governed_by}</Typography>
							</Grid>
							{companiesHouseProfile.companyProfile.foreign_company_details.originating_registry ?
								<Grid item xs={4}>
									<label>Originating Registry</label>
									<Typography variant="body1">
										{companiesHouseProfile.companyProfile.foreign_company_details.originating_registry.country}<br />
										{companiesHouseProfile.companyProfile.foreign_company_details.originating_registry.name}
									</Typography>
								</Grid>
								: null}
							<Grid item xs={4}>
								<label>Foreign Registration Number/ID</label>
								<Typography variant="body1">{companiesHouseProfile.companyProfile.foreign_company_details.registration_number}</Typography>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<Divider light></Divider>
						</Grid>
					</>
				) : ""}

				<Grid container item xs={12} spacing={1}>
					<Grid item xs={12}>
						<Typography variant="subtitle1">Indicators</Typography>
					</Grid>
					<Grid item xs={4}>
						<label>Has Been Liquidated</label>
						<Typography variant="body1">{companiesHouseProfile.companyProfile.has_been_liquidated ? "yes" : "no"}</Typography>
					</Grid>
					<Grid item xs={4}>
						<label>Has Charges</label>
						<Typography variant="body1">{companiesHouseProfile.companyProfile.has_charges ? "yes" : "no"}</Typography>
					</Grid>
					<Grid item xs={4}>
						<label>Has Insolvency History</label>
						<Typography variant="body1">{companiesHouseProfile.companyProfile.has_insolvency_history ? "yes" : "no"}</Typography>
					</Grid>
					<Grid item xs={4}>
						<label>Community Interest Company</label>
						<Typography variant="body1">{companiesHouseProfile.companyProfile.is_community_interest_company ? "yes" : "no"}</Typography>
					</Grid>
					{(companiesHouseProfile.companyProfile.accounts && companiesHouseProfile.companyProfile.accounts.overdue != null) ?
						<Grid item xs={4}>
							<label>Accounts Overdue</label>
							<Typography variant="body1">{companiesHouseProfile.companyProfile.accounts.overdue ? "yes" : "no"}</Typography>
						</Grid> :
						null
					}
					{(companiesHouseProfile.companyProfile.confirmation_statement && companiesHouseProfile.companyProfile.confirmation_statement.overdue != null) ?
						<Grid item xs={4}>
							<label>Confirmation Statement Overdue</label>
							<Typography variant="body1">{
								companiesHouseProfile.companyProfile.confirmation_statement.overdue ? "yes" : "no"}</Typography>
						</Grid> :
						null
					}
				</Grid>

				<Grid item xs={12}>
					<Divider light></Divider>
				</Grid>

				<Grid container item xs={12} spacing={1}>
					<Grid item xs={12}>
						<Typography variant="subtitle1">Officers</Typography>
					</Grid>
					<Grid item xs={4}>
						<label>Active Officers</label>
						<Typography variant="body1">{companiesHouseProfile.officers.active_count}</Typography>
					</Grid>
					<Grid item xs={4}>
						<label>Inactive Officers</label>
						<Typography variant="body1">{companiesHouseProfile.officers.inactive_count}</Typography>
					</Grid>
					<Grid item xs={12}>
						<TableContainer component={Paper} style={{ overflow: "hidden" }}>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell style={{ width: "62px" }} >

										</TableCell>
										<TableCell>
											Name
										</TableCell>
										<TableCell>
											Nationality
										</TableCell>
										<TableCell className="left">
											DoB
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{companiesHouseProfile.officers.items && companiesHouseProfile.officers.items.length > 0 ? (
										<>
											{companiesHouseProfile.officers.items.map((officer: CompanyOfficer) =>
												<>
													<TableRow >
														<TableCell style={{ borderBottom: "none", paddingLeft: "16px" }}>
															<IconButton aria-label="expand row" size="small" onClick={() => officerOpen === officer.name ? setOfficerOpen("") : setOfficerOpen(officer.name)}>
																{officerOpen === officer.name ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
															</IconButton>
														</TableCell>
														<TableCell style={{ borderBottom: "none" }}>
															<div className="table-cell-wrapper">{officer.name}</div>
														</TableCell>
														<TableCell scope="row" className="left" style={{ borderBottom: "none" }}>
															<div className="table-cell-wrapper">{officer.nationality}</div>
														</TableCell>
														{
															officer.date_of_birth ?
																<TableCell scope="row" className="left" style={{ borderBottom: "none" }}>
																	<div className="table-cell-wrapper">{`${officer.date_of_birth.month}/${officer.date_of_birth.year}`}</div>
																</TableCell> :
																null
														}
													</TableRow>
													<TableRow>
														<TableCell colSpan={4} style={{ paddingTop: 0, paddingBottom: 0 }}>
															<Collapse in={officerOpen === officer.name} timeout="auto" unmountOnExit>
																<Box margin={1}>
																	<Grid container spacing={2}>
																		<Grid container item xs={12} spacing={1}>
																			<Grid item xs={4}>
																				<label>Full Name</label>
																				<Typography variant="body1">{officer.name}</Typography>
																			</Grid>
																			<Grid item xs={4}>
																				<label>Role</label>
																				<Typography variant="body1">{officer.officer_role}</Typography>
																			</Grid>
																			<Grid item xs={4}>
																				<label>Occupation</label>
																				<Typography variant="body1">{officer.occupation}</Typography>
																			</Grid>

																			<Grid item xs={4}>
																				<label>Residence</label>
																				<Typography variant="body1">{officer.country_of_residence}</Typography>
																			</Grid>
																			<Grid item xs={4}>
																				<label>Appointed</label>
																				<Typography variant="body1">{officer.appointed_on}</Typography>
																			</Grid>
																			<Grid item xs={4}>
																				<label>Address</label>
																				<Typography variant="body1">
																					{officer.address.address_line_1}<br />
																					{officer.address.address_line_2 ? <>{officer.address.address_line_2}<br /></> : null}
																					{officer.address.locality ? <>{officer.address.locality}<br /></> : null}
																					{officer.address.region ? <>{officer.address.region}<br /></> : null}
																					{officer.address.postal_code ? <>{officer.address.postal_code}<br /></> : null}
																					{officer.address.country ? <>{officer.address.country}<br /></> : null}
																				</Typography>
																			</Grid>
																		</Grid>
																	</Grid>
																</Box>
															</Collapse>
														</TableCell>
													</TableRow>
												</>
											)
											}
										</>

									) : ("No Officers")}
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>

				</Grid>

				<Grid item xs={12}>
					<Divider light></Divider>
				</Grid>

				<Grid container item xs={12} spacing={1}>
					<Grid item xs={12}>
						<Typography variant="subtitle1">Persons of Significant Control</Typography>
					</Grid>
					<Grid item xs={4}>
						<label>Active Officers</label>
						<Typography variant="body1">{companiesHouseProfile.officers.active_count}</Typography>
					</Grid>
					<Grid item xs={4}>
						<label>Inactive Officers</label>
						<Typography variant="body1">{companiesHouseProfile.officers.inactive_count}</Typography>
					</Grid>
					<Grid item xs={12}>
						<TableContainer component={Paper} style={{ overflow: "hidden" }}>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell style={{ width: "62px" }} >

										</TableCell>
										<TableCell>
											Name
										</TableCell>
										<TableCell>
											Nationality
										</TableCell>
										<TableCell className="left">
											DoB
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{companiesHouseProfile.personsOfSignificantControl.items && companiesHouseProfile.personsOfSignificantControl.items.length > 0 ? (
										<>
											{companiesHouseProfile.personsOfSignificantControl.items.map((psc: PersonsOfSignificantControl) =>
												<>
													<TableRow >
														<TableCell style={{ borderBottom: "none", paddingLeft: "16px" }}>
															<IconButton aria-label="expand row" size="small" onClick={() => pscOpen === psc.name ? setPSCOpen("") : setPSCOpen(psc.name)}>
																{pscOpen === psc.name ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
															</IconButton>
														</TableCell>
														<TableCell style={{ borderBottom: "none" }}>
															<div className="table-cell-wrapper">{psc.name}</div>
														</TableCell>
														{psc.kind === PSC_TYPES.INDIVIDUAL ?
															<>
																<TableCell scope="row" className="left" style={{ borderBottom: "none" }}>
																	<div className="table-cell-wrapper">{psc.nationality}</div>
																</TableCell>
																{psc.date_of_birth ?
																	<TableCell scope="row" className="left" style={{ borderBottom: "none" }}>
																		<div className="table-cell-wrapper">{`${psc.date_of_birth.month}/${psc.date_of_birth.year}`}</div>
																	</TableCell> :
																	null
																}
															</>
															: null}
													</TableRow>
													<TableRow>
														{psc.kind === PSC_TYPES.CORPORATE ?
															<TableCell colSpan={4} style={{ paddingTop: 0, paddingBottom: 0 }}>
																<Collapse in={pscOpen === psc.name} timeout="auto" unmountOnExit>
																	<Box margin={1}>
																		<Grid container spacing={2}>
																			<Grid container item xs={12} >
																				<Grid item xs={4}>
																					<label>Kind</label>
																					<Typography variant="body1">{psc.kind}</Typography>
																				</Grid>
																				<Grid item xs={4}>
																					<label>Full Name</label>
																					<Typography variant="body1">{psc.name}</Typography>
																				</Grid>
																				<Grid item xs={4}>
																					<label>Identification</label>
																					<Typography variant="body1">
																						{psc.identification.country_registered ? <>{psc.identification.country_registered}<br /></> : null}
																						{psc.identification.legal_authority}<br />
																						{psc.identification.legal_form}<br />
																						{psc.identification.place_registered ? <>{psc.identification.place_registered}<br /></> : null}
																						{psc.identification.registration_number ? <>{psc.identification.registration_number}<br /></> : null}
																					</Typography>
																				</Grid>
																				<Grid item xs={4}>
																					<label>Notified On</label>
																					<Typography variant="body1">{psc.notified_on}</Typography>
																				</Grid>
																				<Grid item xs={4}>
																					<label>Address</label>
																					<Typography variant="body1">
																						{psc.address.address_line_1}<br />
																						{psc.address.address_line_2}<br />
																						{psc.address.locality}<br />
																						{psc.address.region}<br />
																						{psc.address.postal_code}<br />
																						{psc.address.country}<br />
																					</Typography>
																				</Grid>
																				<Grid item xs={8}>
																					<label>Natures of Control</label>
																					{psc.natures_of_control.map((control: string) =>
																						<Typography variant="body1">{control}</Typography>
																					)}

																				</Grid>
																			</Grid>
																		</Grid>
																	</Box>
																</Collapse>
															</TableCell>
															:
															psc.kind === PSC_TYPES.LEGAL_PERSON ?
																<TableCell colSpan={4} style={{ paddingTop: 0, paddingBottom: 0 }}>
																	<Collapse in={pscOpen === psc.name} timeout="auto" unmountOnExit>
																		<Box margin={1}>
																			<Grid container spacing={2}>
																				<Grid container item xs={12} >
																					<Grid item xs={4}>
																						<label>Kind</label>
																						<Typography variant="body1">{psc.kind}</Typography>
																					</Grid>
																					<Grid item xs={4}>
																						<label>Full Name</label>
																						<Typography variant="body1">{psc.name}</Typography>
																					</Grid>
																					<Grid item xs={4}>
																						<label>Identification</label>
																						<Typography variant="body1">{psc.identification.legal_authority} - {psc.identification.legal_form}</Typography>
																					</Grid>
																					<Grid item xs={4}>
																						<label>Notified On</label>
																						<Typography variant="body1">{psc.notified_on}</Typography>
																					</Grid>
																					<Grid item xs={4}>
																						<label>Address</label>
																						<Typography variant="body1">
																							{psc.address.address_line_1}<br />
																							{psc.address.address_line_2}<br />
																							{psc.address.locality}<br />
																							{psc.address.region}<br />
																							{psc.address.postal_code}<br />
																							{psc.address.country}<br />
																						</Typography>
																					</Grid>
																					<Grid item xs={8}>
																						<label>Natures of Control</label>
																						{psc.natures_of_control.map((control: string) =>
																							<Typography variant="body1">{control}</Typography>
																						)}

																					</Grid>
																				</Grid>
																			</Grid>
																		</Box>
																	</Collapse>
																</TableCell>
																:
																<TableCell colSpan={4} style={{ paddingTop: 0, paddingBottom: 0 }}>
																	<Collapse in={pscOpen === psc.name} timeout="auto" unmountOnExit>
																		<Box margin={1}>
																			<Grid container spacing={2}>
																				<Grid container item xs={12} >
																					<Grid item xs={4}>
																						<label>Kind</label>
																						<Typography variant="body1">{psc.kind}</Typography>
																					</Grid>
																					<Grid item xs={4}>
																						<label>Full Name</label>
																						<Typography variant="body1">{psc.name}</Typography>
																					</Grid>
																					<Grid item xs={4}>
																						<label>Residence</label>
																						<Typography variant="body1">{psc.country_of_residence}</Typography>
																					</Grid>
																					<Grid item xs={4}>
																						<label>Notified On</label>
																						<Typography variant="body1">{psc.notified_on}</Typography>
																					</Grid>
																					<Grid item xs={4}>
																						<label>Address</label>
																						<Typography variant="body1">
																							{psc.address.address_line_1}<br />
																							{psc.address.address_line_2}<br />
																							{psc.address.locality}<br />
																							{psc.address.region}<br />
																							{psc.address.postal_code}<br />
																							{psc.address.country}<br />
																						</Typography>
																					</Grid>
																					<Grid item xs={8}>
																						<label>Natures of Control</label>
																						{psc.natures_of_control.map((control: string) =>
																							<Typography variant="body1">{control}</Typography>
																						)}

																					</Grid>
																				</Grid>
																			</Grid>
																		</Box>
																	</Collapse>
																</TableCell>
														}

													</TableRow>
												</>
											)
											}
										</>

									) : ("No Officers")}
								</TableBody>
							</Table>
						</TableContainer>

					</Grid>

				</Grid>
			</Grid>
		</TabPanel>;
	};

	return (
		<>
			<AppBar className="action-bar" position="static" color="default" elevation={0} style={{ marginTop: "-48px", marginBottom: "48px", marginLeft: "-32px", marginRight: "-32px", width: "auto" }}>
				<Toolbar>
					<Grid container spacing={2} alignItems="center">
						<Grid item>
							<IconButton>
								<ArrowBackIcon className="block" color="inherit" onClick={() => history.goBack()} />
							</IconButton>
						</Grid>
						<Grid item>
							<Typography>{pendingRegistration.id}</Typography>
						</Grid>
					</Grid>

				</Toolbar>
			</AppBar>

			<Snackbar open={error.didError} autoHideDuration={6000} onClose={() => setError({ didError: false, message: "" })}>
				<Alert onClose={() => setError({ didError: false, message: "" })} severity="error">
					{error.message}
				</Alert>
			</Snackbar>

			<Grid container spacing={2} justify="center">
				<Grid item xs={12} lg={7} >
					<Paper className="paper">
						<AppBar className="action-bar" position="static" color="default" elevation={0}>
							<Toolbar>
								<Grid container spacing={2} alignItems="center">
									<Grid item>
										<Typography>Registration</Typography>
									</Grid>
									<Grid item xs>
									</Grid>
								</Grid>
							</Toolbar>
						</AppBar>
						<Dialog fullWidth={true}
							maxWidth={"lg"}
							onClose={closeImageModal}
							aria-labelledby="simple-dialog-title"
							open={imageModalOpen}>
							<DialogTitle id="simple-dialog-title">{imageModalTitle}</DialogTitle>
							<img className="document-image" src={modalImg} alt="id document" />
						</Dialog>
						<div className="card-content-wrapper">
							<div className={`card-menu-container ${classes.root}`}>
								<Tabs
									orientation="vertical"
									variant="scrollable"
									value={value}
									onChange={handleChange}
									aria-label="Vertical tabs example"
									className={classes.tabs}
								>
									<Tab classes={{ wrapper: classes.wrapper }} label="General" {...a11yProps(0)} />
									<Tab classes={{ wrapper: classes.wrapper }} label="Account Holder" {...a11yProps(1)} />
									<Tab classes={{ wrapper: classes.wrapper }} label="Organisation" {...a11yProps(2)} />
									<Tab classes={{ wrapper: classes.wrapper }} label="Companies House Profile" {...a11yProps(3)} disabled={!companiesHouseProfile || (companiesHouseProfile.companyProfile as any).errors || (companiesHouseProfile.personsOfSignificantControl as any).errors} />
									<Tab classes={{ wrapper: classes.wrapper }} label="Charity Commission Profile" {...a11yProps(4)} disabled={!charitiesCommissionProfile} />
								</Tabs>
							</div>
							<div className="card-details-container">
								<TabPanel value={value} index={0}>
									<label>Registration Id</label>
									<p>{pendingRegistration.id}</p>
									<label>Account Email</label>
									<p>{pendingRegistration.accountHolderEmail} {emailExists && emailExists.exists ? <Chip color="secondary" size="small" label={"User already exists with email!"} /> : ""}</p>
									<label>Account Plan</label>
									<p>{pendingRegistration.plan}</p>
									<label>Created</label>
									<p>{pendingRegistration.created}</p>
								</TabPanel>
								<TabPanel value={value} index={1}>
									<label>First Name</label>
									<p>{pendingRegistration.organisationDetails.accountHolderFirstName}</p>
									<label>Last Name</label>
									<p>{pendingRegistration.organisationDetails.accountHolderLastName}</p>

									<label>Date of Birth</label>
									<p>{pendingRegistration.organisationDetails.accountHolderDoB}</p>

									<label>Contact Phone</label>
									<p>{pendingRegistration.organisationDetails.accountHolderContactPhone}</p>
									{pendingRegistration.organisationDetails.organisationType === "soletrader" ||
										pendingRegistration.organisationDetails.organisationType === "other" ?
										(
											<>
												<label>Identity Images</label>
												{pendingRegistration.organisationDetails.identityImagesUrls ? pendingRegistration.organisationDetails.identityImagesUrls.map(url => {
													return (<img src={url} onClick={() => openImageModal(url, "Identity Image")} alt="id document" />);
												}
												) : ""}
											</>
										)
										: ""
									}
								</TabPanel>
								<TabPanel value={value} index={2}>
									<label>Type</label>
									<p>{pendingRegistration.organisationDetails.organisationType}</p>
									{pendingRegistration.organisationDetails.organisationType !== "charity" ?
										<>
											<label>Industry</label>
											<p>{pendingRegistration.organisationDetails.industry}</p>
										</>
										: null
									}
									<label>Name</label>
									<p>{pendingRegistration.organisationDetails.organisationName}</p>
									{pendingRegistration.organisationDetails.organisationType === "registered" ?
										(
											<>
												<label>Company Number</label>
												<p>{pendingRegistration.organisationDetails.companyNumber}</p>
											</>
										)
										: ""
									}
									{pendingRegistration.organisationDetails.organisationType === "charity" ?
										(
											<>
												<label>Charity Number</label>
												<p>{pendingRegistration.organisationDetails.charityNumber}</p>
											</>
										)
										: ""
									}
									<label>Address</label>
									<p>
										{pendingRegistration.organisationDetails.address ?
											(
												<>
													{pendingRegistration.organisationDetails.address.lines.map((line) => <>{line} <br /></>)}
													{pendingRegistration.organisationDetails.address.post_zip_code} <br />
												</>
											)
											:
											""
										}

									</p>

								</TabPanel>
								{companiesHouseProfile && (companiesHouseProfile.companyProfile as any).errors === undefined && (companiesHouseProfile.personsOfSignificantControl as any).errors === undefined ? (
									<>{renderCompaniesHouseProfile(companiesHouseProfile)}</>
								) : <TabPanel value={value} index={3}>
									<Grid container spacing={2}>
										<Grid container item xs={12} spacing={1}>
											<Grid item xs={12}>
												<Typography variant="subtitle1">Loading</Typography>
											</Grid>
										</Grid>
									</Grid>
								</TabPanel>}
								{charitiesCommissionProfile ? (
									<TabPanel value={value} index={4}>
										<Grid container spacing={2}>
											<Grid container item xs={12} spacing={1}>
												<Grid item xs={12}>
													<Typography variant="subtitle1">Details</Typography>
												</Grid>
												<Grid item xs={4}>
													<label>Charity Name</label>
													<Typography variant="body1">{charitiesCommissionProfile.CharityName}</Typography>
												</Grid>
												<Grid item xs={4}>
													<label>Charity Number</label>
													<Typography variant="body1">{charitiesCommissionProfile.RegisteredCharityNumber}</Typography>
												</Grid>
												<Grid item xs={4}>
													<label>Company Number</label>
													<Typography variant="body1">{charitiesCommissionProfile.RegisteredCompanyNumber}</Typography>
												</Grid>
												{charitiesCommissionProfile.WorkingNames ? (<Grid item xs={4}>
													<label>Working Names</label>
													<Typography variant="body1">{charitiesCommissionProfile.WorkingNames}</Typography>
												</Grid>) : ""}
												<Grid item xs={8}>
													<label>Classification</label>
													<Typography variant="body1">
														<b>WHAT</b> - {charitiesCommissionProfile.Classification.What}<br />
														<b>WHO</b> - {charitiesCommissionProfile.Classification.Who}<br />
														<b>HOW</b> - {charitiesCommissionProfile.Classification.How}
													</Typography>
												</Grid>
												<Grid item xs={4}>
													<label>Registered Address</label>
													<Typography variant="body1">
														{charitiesCommissionProfile.Address.Line1 ? <>{charitiesCommissionProfile.Address.Line1}<br /></> : ""}
														{charitiesCommissionProfile.Address.Line2 ? <>{charitiesCommissionProfile.Address.Line2}<br /></> : ""}
														{charitiesCommissionProfile.Address.Line3 ? <>{charitiesCommissionProfile.Address.Line3}<br /></> : ""}
														{charitiesCommissionProfile.Address.Line4 ? <>{charitiesCommissionProfile.Address.Line4}<br /></> : ""}
														{charitiesCommissionProfile.Address.Line5 ? <>{charitiesCommissionProfile.Address.Line5}<br /></> : ""}
														{charitiesCommissionProfile.Address.Postcode}<br />
													</Typography>
												</Grid>
											</Grid>
											<Grid item xs={12}>
												<Divider light></Divider>
											</Grid>
											<Grid container item xs={12} spacing={1}>
												<Grid item xs={12}>
													<Typography variant="subtitle1">Contact</Typography>
												</Grid>
												<Grid item xs={4}>
													<label>Contact Name</label>
													<Typography variant="body1">{charitiesCommissionProfile.ContactName.CharityRoleName ? charitiesCommissionProfile.ContactName.CharityRoleName : "No name"}</Typography>
												</Grid>
												<Grid item xs={4}>
													<label>Public Telephone Number</label>
													<Typography variant="body1">{charitiesCommissionProfile.PublicTelephoneNumber}</Typography>
												</Grid>
												<Grid item xs={4}>
													<label>Email Address</label>
													<Typography variant="body1">{charitiesCommissionProfile.EmailAddress}</Typography>
												</Grid>
												<Grid item xs={4}>
													<label>Website Address</label>
													<Typography variant="body1">{charitiesCommissionProfile.WebsiteAddress}</Typography>
												</Grid>
											</Grid>
											<Grid item xs={12}>
												<Divider light></Divider>
											</Grid>
											<Grid container item xs={12} spacing={1}>
												<Grid item xs={12}>
													<Typography variant="subtitle1">Filings &amp; Registration</Typography>
												</Grid>
												<Grid item xs={4}>
													<label>last Filing</label>
													<Typography variant="body1">{charitiesCommissionProfile.LatestFiling.AccountsReceived}</Typography>
												</Grid>
												<Grid item xs={4}>
													<label>Registration Status</label>
													<Typography variant="body1">{charitiesCommissionProfile.OrganisationType}</Typography>
												</Grid>
												<Grid item xs={4}>
													<label>Is Dissolved</label>
													<Typography variant="body1">{charitiesCommissionProfile.IsCIODissolutionReceived}</Typography>
												</Grid>
												<Grid item xs={4}>
													<label>Insolvency Act Documents</label>
													<Typography variant="body1">{charitiesCommissionProfile.IsInsolvencyActDocsReceived}</Typography>
												</Grid>
											</Grid>
										</Grid>
									</TabPanel>
								) :
									<TabPanel value={value} index={4}>
										<Grid container spacing={2}>
											<Grid container item xs={12} spacing={1}>
												<Grid item xs={12}>
													<Typography variant="subtitle1">Loading</Typography>
												</Grid>
											</Grid>
										</Grid>
									</TabPanel>
								}

							</div>
						</div>

					</Paper>
				</Grid>
				{registrationState !== "new" ? (
					<Grid item xs={12} lg={2}>
						<Paper className="paper">
							<AppBar className="action-bar" position="static" color="default" elevation={0}>
								<Toolbar>
									<Grid container spacing={2} alignItems="center">
										<Grid item>
											<Typography>Assessment</Typography>
										</Grid>
										<Grid item xs>
										</Grid>


									</Grid>
								</Toolbar>
							</AppBar>
							<Box style={{ padding: "10px" }}>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<TextField fullWidth variant="outlined" multiline rows={6} rowsMax={10} name="notes" label="Notes" onChange={handleAssessmentChange} value={kycAssessment.notes}></TextField>
									</Grid>
									<Grid item xs={12}>
										<TextField fullWidth variant="outlined" multiline rows={6} rowsMax={10} name="summary" label="Summary" onChange={handleAssessmentChange} value={kycAssessment.summary}></TextField>
									</Grid>
									<Grid item xs={12}>
										<TextField
											variant="outlined"
											fullWidth
											type="number"
											label="Risk Score"
											value={kycAssessment.riskScore}
											onChange={handleAssessmentChange}
											inputProps={{
												max: 3,
												min: 0
											}} name="riskScore"></TextField>
									</Grid>
									<Grid xs={12} item container justify="flex-end">
										<Grid item>
											<FormLabel component="legend">Assessment Outcome</FormLabel>
										</Grid>
										<Grid item>
											<RadioGroup aria-label="outcome" name="status" value={kycAssessment.status} onChange={handleAssessmentChange}>
												<FormControlLabel labelPlacement="start" value="pending" control={<Radio color="default" />} label="Pending" />
												<FormControlLabel labelPlacement="start" value="approved" control={<Radio color="primary" />} label="Approved" disabled={!emailExists || (emailExists && emailExists.exists)} />
												{emailExists && emailExists.exists ? <Chip color="secondary" size="small" label={"User already exists with email!"} /> : ""}
												<FormControlLabel labelPlacement="start" value="rejected" control={<Radio color="secondary" />} label="Rejected" />
											</RadioGroup>
										</Grid>
									</Grid>
									<Grid item container justify="flex-end">
										<Grid item>
											<Button color="primary" variant="contained" disabled={!validateKyc() || saving} onClick={() => submitKycAssessment()}>{saving ? <CircularProgress size={24} style={{ color: "white", marginRight: "1em" }} /> : ""} Save/Submit</Button>
										</Grid>
									</Grid>
								</Grid>
							</Box>


						</Paper>
					</Grid>) : ""};
			</Grid>

		</>
	);

}