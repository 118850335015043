import React, { useContext, useEffect, useState } from "react";
import {
	Paper,
	AppBar,
	Toolbar,
	Grid,
	IconButton,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Tabs,
	Tab,
	makeStyles,
	Theme,
	Typography,
	Box,
	Button
} from "@material-ui/core";
import {
	AccountSuspensionReason,
	OrganisationDTO,
	PlatformOrganisationDTO
} from "@vibepay/business-common";
import { useHistory } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { FirebaseContext } from "../../../core/firebase";

interface TabPanelProps {
	children?: React.ReactNode;
	index: any;
	value: any;
	classes?: { root: any; };
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, classes, ...other } = props;

	return (
		<div
			role="tabpanel"
			className="tab-panel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3} className={`${classes?.root}`}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

function a11yProps(index: any) {
	return {
		id: `vertical-tab-${index}`,
		"aria-controls": `vertical-tabpanel-${index}`,
	};
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper,
		display: "flex",
	},
	tabs: {
		minWidth: 216,
		borderRight: `1px solid ${theme.palette.divider}`,
	},
	wrapper: {
		alignItems: "flex-start"
	},
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		borderRadius: 10,
		padding: theme.spacing(2, 4, 3),
	},
	table_container: {
		padding: 0,
		marginLeft: "8px"
	}
}));

export function OrganisationOverview(props: any) {

	const firebaseApp = useContext(FirebaseContext);
	const history = useHistory();
	const organisation = props.location?.state?.organisation as PlatformOrganisationDTO;

	const classes = useStyles();
	const [value, setValue] = React.useState(0);


	const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
		setValue(newValue);
	};

	const [organisationView, setOrganisationView] = useState(undefined as OrganisationDTO | undefined);
	
	const fetchOrganisationView = async () => {
		const organisationView = await firebaseApp.fetchOrganisationView(organisation.id);
		setOrganisationView(organisationView);
	};

	const suspendOrganisation = async (suspend: boolean) => {
		await firebaseApp.updateOrganisation({ 
			organisationId: organisation.id, 
			update: {
				suspended: suspend,
			}
		});

		fetchOrganisationView();
	};

	const shadowbanOrganisation = async (shadowban: boolean) => {
		await firebaseApp.updateOrganisation({ 
			organisationId: organisation.id, 
			update: {
				shadowbanned: shadowban
			}
		});

		fetchOrganisationView();
	};	

	useEffect(() => {
		fetchOrganisationView();
	}, []);

	return (
		<>
			<AppBar className="action-bar" position="static" color="default" elevation={0} style={{ marginTop: "-48px", marginBottom: "48px", marginLeft: "-32px", marginRight: "-32px", width: "auto" }}>
				<Toolbar>
					<Grid container spacing={2} alignItems="center">
						<Grid item>
							<IconButton>
								<ArrowBackIcon className="block" color="inherit" onClick={() => history.goBack()} />
							</IconButton>
						</Grid>
						<Grid item>
							<Typography>{organisation.name}</Typography>
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
			<Grid container spacing={2} justify="center">
				<Grid item xs={12} lg={7} >
					<Paper className="paper">
						<AppBar className="action-bar" position="static" color="default" elevation={0}>
							<Toolbar>
								<Grid container spacing={2} alignItems="center">
									<Grid item>
										<Typography>Overview</Typography>
									</Grid>
									<Grid item xs>
									</Grid>
								</Grid>
							</Toolbar>
						</AppBar>
						<div className="card-content-wrapper">
							<div className={`card-menu-container ${classes.root}`}>
								<Tabs
									orientation="vertical"
									variant="scrollable"
									value={value}
									onChange={handleChange}
									aria-label="Vertical tabs example"
									className={classes.tabs}
								>
									<Tab classes={{ wrapper: classes.wrapper }} label="General" {...a11yProps(0)} />
									<Tab classes={{ wrapper: classes.wrapper }} label="Account Holder" {...a11yProps(1)} />
									<Tab classes={{ wrapper: classes.wrapper }} label="Plan Details" {...a11yProps(2)} />
									<Tab classes={{ wrapper: classes.wrapper }} label="Recent Charges" {...a11yProps(3)} />
									<Tab classes={{ wrapper: classes.wrapper }} label="Invoices" {...a11yProps(4)} />
								</Tabs>
							</div>
							<div className="card-details-container">
								<TabPanel value={value} index={0}>
									<label>Organisation Id</label>
									<p>{organisation.id}</p>
									<label>Organisation Name</label>
									<p>{organisation.name}</p>
									<label>Organisation Plan</label>
									<p>{organisation.plan}</p>
									<label>Organisation Type</label>
									<p>{organisation.type}</p>
									<label>Organisation Created</label>
									<p>{(new Date(organisation.created)).toDateString()}</p>
									<label>Status</label>
									<Paper className="paper">
										<TableContainer>
											<Table>
												<TableHead>
													<TableRow>
														<TableCell>
															Property
														</TableCell>
														<TableCell>
															Value
														</TableCell>
														<TableCell>
															Action
														</TableCell>
													</TableRow>
												</TableHead>
												{organisationView ? 
													<TableBody>
														<TableRow>
															<TableCell>
																Suspended
															</TableCell>
															<TableCell>
																{organisationView.state && organisationView.state.isSuspended ? `Yes - ${organisationView.state.suspensionReason}` 
																	: "No" 
																}
															</TableCell>
															<TableCell>
																<Button color="secondary" variant="contained" onClick={() => suspendOrganisation(organisationView.state.isSuspended && (organisationView.state.suspensionReason === AccountSuspensionReason.MANUALLY_SUSPENDED || organisationView.state.suspensionReason === AccountSuspensionReason.SHADOW_BANNED ) ? false : true )}>
																	{organisationView.state.isSuspended && organisationView.state.suspensionReason  ? "Unsuspend": "Suspend"}
																</Button> 
															</TableCell>
														</TableRow>
														<TableRow>
															<TableCell>
																Shadowbanned
															</TableCell>
															<TableCell>
																{organisationView.state && organisationView.state.isShadowbanned ? `Yes - ${organisationView.state.suspensionReason}` 
																	: "No" 
																}
															</TableCell>
															<TableCell>
																<Button color="secondary" variant="contained" onClick={() => shadowbanOrganisation(organisationView.state.isShadowbanned ? false : true )}>
																	{organisationView.state.isShadowbanned  ? "Unshadowban": "Shadowban"}
																</Button> 
															</TableCell>
														</TableRow>
														<TableRow>
															<TableCell>
																Enabled Features
															</TableCell>
															<TableCell>
																{ organisationView.state && organisationView.state.enabledFeatures ?  
																	organisationView.state.enabledFeatures.length > 0 ? organisationView.state.enabledFeatures.map(feature => <p>{feature}</p>) 
																		: "None" 
																	: "Loading" 
																}
															</TableCell>
															<TableCell>
															
															</TableCell>
														</TableRow> 
														<TableRow>
															<TableCell>
																Has Outstanding Invoice
															</TableCell>
															<TableCell>
																{ organisationView.state && organisationView.state.hasOutstandingInvoice ? "Yes" 
																	: "No"
																}
															</TableCell>
															<TableCell>
															
															</TableCell>
														</TableRow> 
														<TableRow>
															<TableCell>
																Is New
															</TableCell>
															<TableCell>
																{ organisationView.state && organisationView.state.isNew ? "Yes" 
																	: "No"
																}
															</TableCell>
															<TableCell>
															
															</TableCell>
														</TableRow> 
													</TableBody> : // Still loading
													<TableBody>
														<TableRow>
															<TableCell colSpan={3} style={{ textAlign: "center" }}>
																Loading
															</TableCell>
														</TableRow>
													</TableBody> 
												}
											</Table>
										</TableContainer>
									</Paper>
								</TabPanel>
								<TabPanel value={value} index={1}>
									<label>Name</label>
									<p>{organisation.organisationDetails.accountHolderFirstName} {organisation.organisationDetails.accountHolderLastName}</p>
									<label>Email</label>
									<p>{organisation.accountHolderEmail}</p>
									<label>Contact Phone</label>
									<p>{organisation.organisationDetails.accountHolderContactPhone}</p>
								</TabPanel>
								<TabPanel value={value} index={2}>
									<label>Organisation Plan</label>
									<p>{organisation.plan}</p>
									<label>Monthly Rate</label>
									<p>Placeholder</p>
									<label>Transaction Rate</label>
									<p>Placeholder %</p>
								</TabPanel>
								<TabPanel value={value} index={3} classes={{ root: classes.table_container }}>
									<TableContainer style={{ overflow: "hidden" }}>
										<Table>
											<TableHead>
												<TableRow>
													<TableCell style={{ width: "62px" }} >

													</TableCell>
													<TableCell>
														Period
													</TableCell>
													<TableCell>
														Issued
													</TableCell>
													<TableCell>
														Due
													</TableCell>
													<TableCell className="left">
														Paid
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												<TableRow>
													<TableCell colSpan={5} style={{ textAlign: "center" }}>
														No Charges - PlaceHolder
													</TableCell>
												</TableRow>
											</TableBody>
										</Table>
									</TableContainer>
								</TabPanel>
								<TabPanel value={value} index={4} classes={{ root: classes.table_container }}>
									<TableContainer style={{ overflow: "hidden" }}>
										<Table>
											<TableHead>
												<TableRow>
													<TableCell style={{ width: "62px" }} >

													</TableCell>
													<TableCell>
														Period
													</TableCell>
													<TableCell>
														Issued
													</TableCell>
													<TableCell>
														Due
													</TableCell>
													<TableCell className="left">
														Paid
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												<TableRow>
													<TableCell colSpan={5} style={{ textAlign: "center" }}>
														No Invoices - PlaceHolder
													</TableCell>
												</TableRow>
											</TableBody>
										</Table>
									</TableContainer>
								</TabPanel>

							</div>
						</div>

					</Paper>
				</Grid>
			</Grid>

		</>
	);

}