import { useAuth0 } from "@auth0/auth0-react";
import { AppBar, Avatar, Button, createStyles, Grid, Hidden, IconButton, Link, Tab, Tabs, Theme, Toolbar, Tooltip, Typography, WithStyles, withStyles } from "@material-ui/core";
import { Help as HelpIcon, Menu as MenuIcon, Notifications as NotificationsIcon } from "@material-ui/icons";
import React, { useCallback, useEffect, useState } from "react";
import { Category } from "./navigator";

const lightColor = "rgba(255, 255, 255, 0.7)";

const styles = (theme: Theme) =>
	createStyles({
		secondaryBar: {
			zIndex: 0,
		},
		menuButton: {
			marginLeft: -theme.spacing(1),
		},
		iconButtonAvatar: {
			padding: 4,
		},
		link: {
			textDecoration: "none",
			color: lightColor,
			"&:hover": {
				color: theme.palette.common.white,
			},
		},
		button: {
			borderColor: lightColor,
		},
		title: {
			textTransform: "capitalize"
		}
	});

interface HeaderProps extends WithStyles<typeof styles> {
	onDrawerToggle: () => void,
	title: string,
	subtitle?: string,
	tabs: Category[] | undefined,
	initialTab?: Category,
	onSubCategorySelected: (subCategory: Category) => void;
}

function Header(props: HeaderProps) {
	const { classes, onDrawerToggle, onSubCategorySelected, tabs, initialTab, title, subtitle } = props;

	const [tabSelected, setTabSelected] = useState(0);
	const { logout } = useAuth0();

	const switchTab = useCallback((value: number) => {
		if (tabs) {
			setTabSelected(value);
			onSubCategorySelected(tabs[value]);
		}
	}, [tabs, onSubCategorySelected]);

	const initializeTab = useCallback(() => {
		if (initialTab && tabs) {
			switchTab(tabs.indexOf(initialTab));
		}
	}, [tabs, switchTab, initialTab]);

	useEffect(() => {
		initializeTab();
	}, [initializeTab]);

	const handleTabSelected = (event: React.ChangeEvent<{}>, newValue: number) => {
		switchTab(newValue);
	};

	const triggerLogout = () => {
		logout({
			returnTo: window.location.origin
		});
	};

	return (
		<React.Fragment>
			<AppBar color="primary" position="sticky" elevation={0}>
				<Toolbar>
					<Grid container spacing={1} alignItems="center">
						<Hidden smUp>
							<Grid item>
								<IconButton
									color="inherit"
									aria-label="open drawer"
									onClick={onDrawerToggle}
									className={classes.menuButton}
								>
									<MenuIcon />
								</IconButton>
							</Grid>
						</Hidden>
						<Grid item xs />
						<Grid item>
							<Link className={classes.link} href="#" variant="body2">
								Go to docs
							</Link>
						</Grid>
						<Grid item>
							<Tooltip title="Alerts • No alerts">
								<IconButton color="inherit">
									<NotificationsIcon />
								</IconButton>
							</Tooltip>
						</Grid>
						<Grid item>
							<IconButton color="inherit" className={classes.iconButtonAvatar}>
								<Avatar src="/static/images/avatar/1.jpg" alt="My Avatar" />
							</IconButton>
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
			<AppBar
				component="div"
				className={classes.secondaryBar}
				color="primary"
				position="static"
				elevation={0}
			>
				<Toolbar>
					<Grid container alignItems="center" spacing={1}>
						<Grid item xs>
							<Typography color="inherit" variant="h5" component="h1" className={classes.title}>
								{title}
							</Typography>
							{subtitle ? <Typography color="inherit" variant="h6" component="h1" className={classes.title}>
								{subtitle}
							</Typography> : null}
						</Grid>
						<Grid item>
							<Button onClick={() => triggerLogout()} className={classes.button} variant="outlined" color="inherit" size="small">
								logout
              				</Button>
						</Grid>
						<Grid item>
							<Tooltip title="Help">
								<IconButton color="inherit">
									<HelpIcon />
								</IconButton>
							</Tooltip>
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
			<AppBar
				component="div"
				className={classes.secondaryBar}
				color="primary"
				position="static"
				elevation={0}
			>
				<Tabs value={tabSelected} onChange={handleTabSelected} textColor="inherit">
					{tabs ?
						tabs.map(tab => {
							return <Tab textColor="inherit" label={tab.id} />;
						}) : ""
					}
				</Tabs>
			</AppBar>
		</React.Fragment>
	);
}

export default withStyles(styles)(Header);